import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  userData: null,
};

export const loginReducer = createSlice({
  name: 'loginReducer',
  initialState,
  reducers: {
    setUserData: (state, action) => {
      state.userData = action.payload || null;
    },
  },
});

export const { setUserData } = loginReducer.actions;

export default loginReducer.reducer;
