import React from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { getProfile } from 'utils/auth';

const browserHistory = createBrowserHistory();
const AuthLayout = React.lazy(() => import('layouts/Auth'));
const AdminLayout = React.lazy(() => import('layouts/Admin'));

const PrivateRoute = ({ component: Component, ...rest }) => {
  const userData = getProfile();
  const token = userData?.token || undefined;

  return (
    <div>
      <Route
        {...rest}
        render={(props) =>
          token ? <Component {...props} /> : <Redirect to="/auth" />
        }
      />
    </div>
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.any,
};

const RouterPath = () => {
  const userData = getProfile();
  const role = userData?.data?.level || '';
  const redirectUrl = role === '1' ? '/admin/dashboard' : '/admin/orgchart';

  return (
    <BrowserRouter history={browserHistory}>
      <Switch>
        <Route path="/auth" component={AuthLayout} />
        <PrivateRoute path="/admin" component={AdminLayout} />
        <Redirect from="/" to={redirectUrl} />
      </Switch>
    </BrowserRouter>
  );
};

export default RouterPath;
