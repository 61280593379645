import axios from 'axios';

export const axiosV1 = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

export function setupAxios(httpClient) {
  httpClient.interceptors.request.use(
    (config) => {
      const axiosConfig = config;
      axiosConfig.baseURL = process.env.REACT_APP_API_BASE_URL;
      axiosConfig.headers.common.Accept = '*/*';

      return axiosConfig;
    },
    (err) => Promise.reject(err),
  );

  httpClient.interceptors.response.use(
    (response) => response,
    (error) => {
      return Promise.reject(error);
    },
  );
}
