import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  repline_last_date: '',
  mpp_last_data: '',
};

export const dashboardReducer = createSlice({
  name: 'dashboardReducer',
  initialState,
  reducers: {
    reducerSetReplineDate: (state, action) => {
      state.repline_last_date = action.payload;
    },
    reducerSetMppDate: (state, action) => {
      state.mpp_last_data = action.payload;
    },
  },
});

export const {
  reducerSetReplineDate,
  reducerSetMppDate,
} = dashboardReducer.actions;

export default dashboardReducer.reducer;
