import React, { Suspense } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import { Backdrop, CircularProgress } from '@material-ui/core';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';

import Route from 'router/routes';
import store from 'utils/configureStore';

import 'semantic-ui-css/semantic.min.css';

const LoadingComponent = () => (
  <>
    <Backdrop open className="fully-loading">
      <CircularProgress color="inherit" />
    </Backdrop>
  </>
);

const App = () => {
  const persistor = persistStore(store);
  const theme = createMuiTheme({
    palette: {
      primary: { main: '#e4636d' },
    },
  });

  return (
    <div className="App">
      <MuiThemeProvider theme={theme}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <Suspense fallback={<LoadingComponent />}>
              <Route />
            </Suspense>
          </PersistGate>
        </Provider>
      </MuiThemeProvider>
    </div>
  );
};

export default App;
