import store from 'utils/configureStore';
const CryptoJS = require('crypto-js');

export const baseAuthName = process.env.REACT_APP_BASE_AUTH_NAME;

export const encryptData = (data) => {
  return CryptoJS.AES.encrypt(data, baseAuthName).toString();
};

export const getProfile = () => {
  const userHased = store?.getState()?.auth?.userData;
  if (userHased) {
    const bytes = CryptoJS.AES.decrypt(userHased, baseAuthName);
    const originalText = bytes.toString(CryptoJS.enc.Utf8);
    if (originalText) return JSON.parse(originalText);
    return null;
  }
  return null;
};
