/*!

=========================================================
* Material Dashboard PRO React - v1.10.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import { disableReactDevTools } from '@fvilers/disable-react-devtools';

import { setupAxios } from 'utils/axiosConfig';

import App from './App';

import 'bootstrap/dist/css/bootstrap.css';
import 'assets/scss/material-dashboard-pro-react.scss?v=1.10.0';

setupAxios(axios);

if (process.env.NODE_ENV === 'production') {
  disableReactDevTools();
}

ReactDOM.render(<App />, document.getElementById('root'));
