import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  isAfterLogin: false,
  finishRefreshToken: false,
};

export const refreshTokenReducer = createSlice({
  name: 'refreshTokenReducer',
  initialState,
  reducers: {
    setFinishRefreshToken: (state, action) => {
      state.finishRefreshToken = action.payload;
    },
    setAfterLogin: (state, action) => {
      state.isAfterLogin = action.payload;
    },
  },
});

export const {
  setFinishRefreshToken,
  setAfterLogin,
} = refreshTokenReducer.actions;

export default refreshTokenReducer.reducer;
