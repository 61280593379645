import { combineReducers } from 'redux';
import loginSlice from 'store/loginSlice';
import dashboardSlice from 'store/dashboardSlice';
import refreshTokenSlice from 'store/refreshTokenSlice';

export default function createReducer() {
  const rootReducer = combineReducers({
    auth: loginSlice,
    refresher: refreshTokenSlice,
    dashboard: dashboardSlice,
  });

  return rootReducer;
}
